@import '../../../../styles/shared';

.list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 25px;
  row-gap: 10px;

  @include mq($from: desktop) {
    flex-wrap: nowrap;
    gap: 25px;
    row-gap: 10px;
  }
}

.anchor {
  font-size: 12px;
  font-weight: 400;
  line-height: 10px;
  text-decoration: none;
  white-space: nowrap;
  color: var(--classic-footer-color);

  &:hover {
    text-decoration: underline;
  }

  @include mq($from: tablet) {
    font-size: 14px;
  }

  @include mq($from: wide) {
    font-size: 16px;
  }
}
