@import '../../../../styles/shared';

.heading {
  margin-bottom: 10px;
  font-weight: 700;
  line-height: 24px;

  @include mq($from: desktop) {
    margin-bottom: 20px;
    font-size: 20px;
  }
}

.listItem {
  &:not(:first-child) {
    margin-top: 10px;
  }
}

.anchor {
  font-size: 12px;
  font-weight: 400;
  line-height: 10px;
  text-decoration: none;
  color: var(--classic-footer-color);

  &:hover {
    text-decoration: underline;
  }

  @include mq($from: tablet) {
    font-size: 14px;
  }

  @include mq($from: wide) {
    font-size: 16px;
  }
}
