@import '../../../../styles/shared';

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 30px;
  row-gap: 12px;

  @include mq($from: desktop) {
    justify-content: flex-end;
    column-gap: 22px;
    row-gap: 12px;
  }

  @include mq($from: wide) {
    column-gap: 50px;
  }
}

.anchor {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  text-decoration: none;
  color: #000;
  background-color: #fff;
  border-radius: 50%;
}

.icon {
  font-size: 22px;
  color: #555;
}

.customIcon {
  img {
    display: block;
    width: 18px;
  }
}
